/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  NgZone,
  ChangeDetectorRef,
  AfterViewInit,
  Inject,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer, MatSidenav } from '@angular/material/sidenav';
import { DialogPosition } from '@angular/material/dialog';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { LoggedInUser } from '@mrsoftebs/data';
import { AccountService } from 'libs/account/src/lib/services/account.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TokenService } from '../../services/token.service';
import { AppsComponent } from '../apps/apps.component';
import { GatewayFacade } from 'libs/gateway/src/lib/states/+gateway/gateway.facades';
import { take } from 'rxjs/operators';
import { LogService } from '../../services/log.service';
import { Socket } from 'ngx-socket-io';
import { NotificationService } from '../../services/notification.service';
import { UserService } from '../../services/user.service';
import { Notification } from '@mrsoftebs/data';
import { NotificationviewerComponent } from '../notificationviewer/notificationviewer.component';
import { NotifierComponent } from '../notifier/notifier.component';
import { MenuService } from '../../services/menu.service';
import { GatewayService } from 'libs/gateway/src/lib/services/gateway.service';
import { Location } from '@angular/common';
import { ErrorComponent } from './error/error.component';
import { HttpClient } from '@angular/common/http';
import { environment } from 'apps/ebs/src/environments/environment';

const api = environment.apiUrl;

@Component({
  selector: 'mrsoftebs-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
})
export class TopNavComponent implements OnInit, AfterViewInit {
  dialogPosition: DialogPosition;
  loading = false;
  loggedInUser: LoggedInUser;
  isLoggedIn;
  moduleItems: Array<{
    itemName: string;
    itemIcon: string;
    itemLink: string;
    global?: boolean;
    moduleActions: Array<{ actionName: string; itemLink: string }>;
  }> = [];
  module = JSON.parse(localStorage.getItem('module'));
  // module: BehaviorSubject<{
  //   itemIcon: string;
  //   itemLink: string;
  //   itemName: string;
  //   moduleActions: Array<any>;
  // }> = new BehaviorSubject<{
  //   itemIcon: string;
  //   itemLink: string;
  //   itemName: string;
  //   moduleActions: Array<any>;
  // }>(null);

  applications$: any;
  isLoading$: any;
  staffRoles: any[] = [];
  logoPath: string;
  applications = JSON.parse(localStorage.getItem('Application'));
  notifications: Notification[] = [];
  menus = [];
  allMenus = [];
  submenu = [];
  isClient = localStorage.getItem('isClient');
  SubScriberName = 'Laser';
  errorMessage: BehaviorSubject<string> = new BehaviorSubject(null);
  myNetworkInterval: NodeJS.Timeout;
  constructor(
    private gatewayFacade: GatewayFacade,
    private dialog: MatDialog,
    private tokenService: TokenService,
    private accountService: AccountService,
    mediaObserver: MediaObserver,
    private _router: Router, // private loginservice: LoginService,
    // private socket: Socket,
    private logService: LogService,
    // private notifier: NotificationService,
    private userService: UserService,
    private menuService: MenuService,
    private gatewayService: GatewayService,
    private location: Location,
    private ngZone: NgZone,
    private cdR: ChangeDetectorRef,
    private http: HttpClient
  ) {
    // this._router.events.subscribe((val) => {
    //   console.log('Location: ', location.path());
    //   if (this.location.path() != '') {
    //     const module = JSON.parse(localStorage.getItem('module'));
    //     const exist = this.staffRoles.find((a) =>
    //       a.itemLink.includes(location.path())
    //     );
    //     console.log('Path exist: ', exist);
    //     // if (!exist) {
    //     //   this.dialog.open(ErrorComponent, {
    //     //     backdropClass: 'true',
    //     //     data: {
    //     //       message: `You don't have permission to acess the requested resource. We're redirecting you to home page`,
    //     //     },
    //     //   });
    //     //   setTimeout(() => {
    //     //     this._router.navigate(['/']);
    //     //   }, 5000);
    //     // }
    //     if (module) {
    //       this.module = module;
    //     }
    //   }
    // });
    this.http.get(environment.apiUrl + 'update').subscribe((result) => {
      console.log('database update result: ', result);
    });
  }

  SubscriberId = '';
  opened = true;
  over = 'side';
  expandHeight = '42px';
  collapseHeight = '42px';
  displayMode = 'flat';
  showprofile = false;
  // overlap = false;
  watcher: Subscription;
  activeMediaQuery = '';
  title = 'app';
  collapseTwo = false;
  @ViewChild('sidenav', { static: true }) sidenav: MatDrawer;

  payload: any;
  user = JSON.parse(localStorage.getItem('EBSloggedInUser'));
  config = {
    paddingAtStart: true,
    interfaceWithRoute: true,
    classname: 'my-custom-class',
    fontColor: `rgb(170, 170, 170)`,
    selectedListFontColor: `white`,
    highlightOnSelect: true,
    collapseOnSelect: true,
    rtlLayout: false,
  };
  ngOnInit() {
    this.myNetworkInterval = setInterval(() => this.checkNetwork(), 5000);
    this.loggedInUser = this.accountService.getLoggedInUser();
    console.log('Is Client: ', this.isClient);
    console.log('loggedInUser: ', this.loggedInUser);
    if (
      this.loggedInUser.subscriberName == null ||
      this.loggedInUser.subscriberName === ''
    ) {
      // this.getSubscriberName();
    }
    this.menuService.getMenu().subscribe((menus) => {
      console.log('Fetched Menu: ', menus);

      // this.menus = menus;
      // const filteredMenu = [];
      menus.forEach((menu) => {
        if (!menu.parentId) {
          const moduleActions = menus.filter((a) => a.parentId == menu.menuId);
          const transformed = moduleActions.map((value) => {
            return { actionName: value.name, itemLink: value.link };
          });

          this.menus.push(menu);
          this.moduleItems.push({
            itemName: menu.name,
            itemIcon: menu.iconPath,
            itemLink: menu.link,
            moduleActions: transformed,
          });
          this.moduleItems?.sort(
            (a, b) =>
              b.itemName !== undefined &&
              a.itemName !== undefined &&
              a.itemName
                .toLocaleLowerCase()
                .localeCompare(b.itemName.toLocaleLowerCase())
          );
        }
      });
      console.log('modules: ', this.moduleItems);
      // .subscribe((saved) => {});
      setTimeout(() => {
        this.getNotifications();
        const data = {
          // socketId: this.socket.ioSocket.id,
          _id: this.loggedInUser.userId,
        };
        this.userService
          .updateUser(data)
          .subscribe((result) => console.log('updateResult: ', result));
        // this.socket.emit('greet', {
        //   socketId: this.socket.ioSocket.id,
        //   userId: this.loggedInUser.userId,
        // });
      }, 3000);

      // this.logService.log('SocketId: ', this.socket.ioSocket);
      // this.socket.fromEvent('welcome').subscribe((data) => {
      //   // this.logService.log('Socket message from service: ', data);
      // });
      // this.socket.fromEvent<any>('notify').subscribe((data) => {
      //   // this.logService.log('Socket message from service: ', data);
      //   this.notifications.push(data);
      //   this.dialog
      //     .open(NotifierComponent, {
      //       data: data,
      //       position: {
      //         left: 'auto',
      //         right: '-10px',
      //         top: 'auto',
      //         bottom: '10px',
      //       },
      //       // hasBackdrop: false,
      //       backdropClass: 'backdrop',
      //       disableClose: false,
      //     })
      //     .afterClosed()
      //     .subscribe((result) => {
      //       if (result) {
      //         // User wants to read immediately
      //         this.viewNotification(result);
      //       }
      //     });
      // });
      // this.socket.fromEvent('greet').subscribe((data) => {
      //   // this.logService.log('greet from server: ', data);
      // });
      // this.socket.emit('greet', 'Greetings from client');

      if (this.loggedInUser) {
        this.getLogo();
      }
      this.isLoading$ = this.gatewayFacade.isLoadingGateway('Application');
      this.applications$ = this.gatewayFacade.loadGateway('Application');
      this.applications$.subscribe((apps) => {
        // console.log('Apps: ', apps);
        this.applications = apps;
        if (apps && apps.length) {
          if (localStorage.getItem('Application')) {
            localStorage.removeItem('Application');
            localStorage.setItem('Application', JSON.stringify(apps));
          } else {
            localStorage.setItem('Application', JSON.stringify(apps));
          }
        }
      });
      if (!this.staffRoles.length) {
        this.gatewayFacade
          .genericFilterGateway(
            'StaffRoleNew',
            'StaffId',
            this.loggedInUser.userId
          )
          .pipe(take(2))
          .subscribe((data) => {
            // const module = this.module.value;
            this.logService.log('Staff roles@topNav: ', data);
            localStorage.setItem('userRoles', JSON.stringify(data));
            this.staffRoles = data;
            console.log('Url: ', this.location.path());
            // if (this.staffRoles.length) {
            const menu = this.staffRoles.filter(
              (a) => a.link === this.location.path()
            );
            console.log('menu: ', menu);
            this.myMenus();
            const item = JSON.parse(localStorage.getItem('module'));
            // this.module = new BehaviorSubject<any>('');
            // this.submenu = item.moduleActions;
            // this.module.next(item.moduleActions);
            this.logService.log('Modules ', item?.moduleActions);
            if (this.module?.moduleActions !== undefined) {
              for (const mod of this.module?.moduleActions) {
                mod['canAccess'] = this.canAccessAction(mod);
              }
            }
          });
      }

      this.sidenav.close();
      // this.module = JSON.parse(localStorage.getItem('module'));
      // if (this.module) {
      //   this.sidenav.close();
      // } else {
      //   this.sidenav.open();
      // }
    });
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    console.log('Url: ', this.location.path(), ' list: ', this.staffRoles);
    // if (this.staffRoles.length) {
    const menu = this.staffRoles.filter((a) => a.link === this.location.path());
    console.log('menu: ', menu);
    // }
  }
  getSubscriberName() {
    this.gatewayService
      .getAllGeneric('IdentityUser', {
        SubscriberId: this.loggedInUser.SubscriberId,
        UserType: 'Subscriber',
      })

      .pipe(take(2))
      .subscribe((data) => {
        this.logService.log('Subscriber data: ', data);
        const newData = data.filter((n) => n['SubscriberName'] !== null);
        if (newData.length > 0) {
          this.loggedInUser.subscriberName = newData[0]['SubscriberName'];
          this.loggedInUser.subscriberShortName = newData[0]['SubscriberName'];
        }
      });
  }
  checkNetwork() {
    if (environment.production) {
      if (!navigator.onLine) {
        this.errorMessage.next(
          `It seems you don't have active internet connection. Check your internet connection and try again`
        );
      } else {
        this.errorMessage.next(null);
      }
    } else {
      this.http.head(api).subscribe(
        (result) => {
          this.errorMessage.next(null);
        },
        (err) => {
          this.errorMessage.next(
            `It seems you don't have active internet connection. Check your internet connection and try again`
          );
        }
      );
    }
  }
  myMenus() {
    const menus = [];

    //console.log("Staff Roles ", this.staffRoles);

    if (this.staffRoles.length > 0) {
      this.staffRoles.forEach((role) => {
        const found = this.moduleItems.find((a) =>
          a?.itemLink?.includes(role?.link)
        );
        if (found) {
          menus.push(found);
        }
      });
    }

    const global = this.menus.filter((m) => m.global == true);

    this.allMenus = [...global, ...new Set(menus)];

    // console.log('Menu Len: ', [...new Set(menus), ...global]);

    //return [...global, ...new Set(menus)];
  }
  getNotifications() {
    // this.notifier.getMyNotifications().subscribe((notifications) => {
    // this.logService.log('Notifications: ', notifications);
    //   this.notifications = notifications;
    // });
  }
  canAccessAction(item) {
    // console.log('Staff role of can Access ', this.staffRoles);
    const canaccess = this.staffRoles?.find(
      (l) => l?.link?.toString().trim() === item?.itemLink?.trim()
    );

    return canaccess ? true : false;
  }

  navigate(item) {
    console.clear();
    // this.module = item;

    // this.module = new BehaviorSubject<any>('');
    // this.module.next(item.moduleActions);
    this.module = item;
    localStorage.setItem('module', JSON.stringify(item));
    this._router.navigate([item?.itemLink]);
    this.submenu = item.moduleActions;
    this.sidenav.close();
    // this.cdR.detectChanges();
    // console.log('Selected Module:', this.module);
  }

  navigateAction(item) {
    this._router.navigate([item?.itemLink]);
  }

  viewNotification(notice) {
    this.dialog
      .open(NotificationviewerComponent, {
        data: notice,

        minWidth: '600px',
        minHeight: '90vh',
        panelClass: '',
        position: { left: 'auto', right: '-10px', top: '50px' },
        // hasBackdrop: false,
        backdropClass: 'backdrop',
        disableClose: false,
      })
      .beforeClosed()
      .subscribe((result) => {
        const index = this.notifications.findIndex((a) => notice);
        this.notifications.splice(index, 1);
      });
  }

  getLogo() {
    // console.log('user: ', this.loggedInUser, ' subId: ', this.SubscriberId);
    this.logoPath = localStorage.getItem('EBSLogo');
    // if (!this.logoPath) {
    this.accountService
      .getLogo(this.loggedInUser.SubscriberId)
      .subscribe((data) => {
        localStorage.setItem('EBSLogo', data?.LogoPath);
        this.logoPath = data?.LogoPath;
        this.SubScriberName = data?.SubscriberName;
        // console.log('logoData: ', data);
        // console.log('SubscriberName: ', this.SubScriberName);
      });
    // }
  }

  logout(data?) {
    this.tokenService.logout();
  }
  toggle() {
    this.sidenav.toggle();
  }
  showApps() {
    this.dialog.open(AppsComponent, {
      width: '400px',
      height: '450px',
      panelClass: '',
      position: { left: 'auto', right: '-10px', top: '50px' },
      // hasBackdrop: false,
      backdropClass: 'backdrop',
      disableClose: false,
    });
  }

  setting() {
    // console.log()
  }
  profile() {
    // console.log();
  }
  ngOnDestroy(): void {
    clearInterval(this.myNetworkInterval);
  }
}
