<mat-toolbar
  class="example-toolbar mat-elevation-z8"
  style="background-color: white"
>
  <mat-toolbar-row *ngIf="isClient != 'true'">
    <button
      mat-icon-button
      class="example-icon"
      aria-label="Example icon-button with menu icon"
    >
      <mat-icon (click)="toggle()">swap_horiz</mat-icon>
    </button>
    <span *ngIf="loggedInUser?.logoPath || logoPath"
      ><img
        [src]="loggedInUser?.logoPath ? loggedInUser?.logoPath : logoPath"
        alt="Logo"
        style="width: 50px; height: auto"
    /></span>
    <span style="margin-left: 12px">
      <h5 *ngIf="loggedInUser && loggedInUser?.SubscriberName?.length > 25">
        {{ loggedInUser?.SubscriberName }}
      </h5>
      <h5 *ngIf="!loggedInUser && SubscriberName">
        {{ SubscriberName }}
      </h5>

      <!-- {{ loggedInUser.subscriberName }} -->
    </span>
    <span class="example-spacer"></span>
    <!-- Right floated items -->

    <img
      class="m-4"
      *ngIf="!loggedInUser?.passportUrl"
      src="assets/images/top_nav/user.jpg"
      class="nav_img-icon"
      alt=""
      height="30px"
      style="border-radius: 30px; width: 30px"
    />

    <img
      class="m-4"
      *ngIf="loggedInUser?.passportUrl"
      [src]="loggedInUser?.passportUrl"
      class="nav_img-icon"
      alt=""
      height="30px"
      style="border-radius: 30px; width: 30px"
    />
    <p class="greetings m-4">
      <mat-icon
        class="m-4"
        matBadge="{{ notifications?.length }}"
        matBadgeColor="warn"
        matBadgePosition="below|after"
        [matMenuTriggerFor]="notice"
      >
        notification_important</mat-icon
      >
      <!-- <mat-icon><mat-chip>{{notifications?.length}}</mat-chip>
      </mat-icon> -->
      <span class="name m-4" *ngIf="loggedInUser">
        <button class="m-4" mat-button [matMenuTriggerFor]="menu">
          {{ loggedInUser?.StaffName }}
          <span
            ><img
              src="sassets/images/top_nav/more-alt.svg"
              height="15px"
              style="margin-left: 5px"
              alt=""
          /></span>
        </button>
      </span>
    </p>
    <mat-menu #notice="matMenu">
      <!-- <span > -->

      <button
        mat-menu-item
        *ngFor="let notify of notifications"
        [matTooltip]="'Click to view'"
        (click)="viewNotification(notify)"
      >
        You have a new {{ notify.notificationType }}
      </button>
      <!-- </span> -->
    </mat-menu>
    <mat-icon class="m-4" matTooltip="Open Apps" (click)="showApps()"
      >view_module</mat-icon
    >
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="profile()">
        <mat-icon>perm_identity</mat-icon> Profile
      </button>
      <button mat-menu-item (click)="navigate({ itemLink: 'settings' })">
        <mat-icon aria-hidden="false" aria-label="{2:label} icon"
          >settings</mat-icon
        >
        Setting
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon aria-hidden="false" aria-label="{2:label} icon"
          >logout</mat-icon
        >
        Log out
      </button>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container class="example-container">
  <mat-sidenav #sidenav style="display: inline">
    <div class="box green_box"></div>
    <div class="box pink_box" style="margin-top: 0vh; margin-right: 0vh"></div>

    <div class="main">
      <div class="hold_center">
        <div class="the_menu" *ngIf="allMenus.length > 0">
          <div *ngFor="let item of allMenus" style="display: inline-block">
            <div class="menu_item" (click)="navigate(item)">
              <img
                *ngIf="item?.itemIcon"
                [src]="item?.itemIcon"
                class="img"
                height="70%"
                alt=""
              />
              <h2 class="title" style="display: block; text-align: center">
                {{ item?.itemName }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      style="text-align: center"
      *ngIf="staffRoles?.length <= 0 && loggedInUser?.userType == 'Subscriber'"
    >
      <button
        mat-raised-button
        color="primary"
        (click)="navigateAction({ itemLink: 'auth/application' })"
      >
        Enable Application Modules
      </button>
    </div>
    <div
      style="text-align: center"
      *ngIf="staffRoles?.length <= 0 && loggedInUser.userType !== 'Subscriber'"
    >
      <h2 class="ask-text">
        Sorry There is no function assigned to you yet. please contact your
        manager to assign roles to you
        <!-- <button mat-button (click)="navigateAction({itemLink:'auth/application'})">Go
          to Application </button> -->
      </h2>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <!-- <ng-content></ng-content> -->
    <!-- Beginning of Dynamic Toolbar -->
    <div class="the_top" *ngIf="isClient != 'true'">
      <h4 class="title" style="display: block">{{ module?.itemName }}</h4>

      <div class="other_links">
        <div
          style="display: inline-block"
          *ngFor="let item of module?.moduleActions"
        >
          <button
            class="links"
            (click)="navigateAction(item)"
            *ngIf="item.actionName && canAccessAction(item)"
          >
            {{ item?.actionName }}
          </button>
        </div>
      </div>
    </div>
    <div
      *ngIf="errorMessage | async"
      style="width: fit-content; text-align: center; align-items: center"
    >
      <div
        class="alert alert-danger larger"
        role="alert"
        style="font-size: 12pt"
      >
        {{ errorMessage | async }}
      </div>
    </div>
    <!-- <app-sub-menu [isClient]="isClient" [module$]="module"></app-sub-menu> -->
    <!-- End of Dynamic Toolbar -->
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
