import { BaseModel } from "./base";
export class ApplicantDto extends BaseModel {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
  residentialAddress: string;
  lgaResidence: string;
  dateOfBirth: Date;
  stateOfOrigin: string;
  age: number;
  lgaOfOrigin: string;
  educationalQualification: string;
  positionId: string;
  categoryId: string;
  facultyId: string;
  applicationId: string;
  departmentId: string;
  positionName: string;
  categoryName: string;
  facultyName: string;
  departmentName: string;
  genderName: string;
  genderId: string;
  maritalStatusId: string;
  maritalStatusName: string;
  lgaResidenceName: string;
  stateOfOriginName: string;
  stateOfResidenceName: string;
  stateOfResidence: string;
  middleName: string;
  lgaOfOriginName: string;
  titleId: string;
  titleName: string;
  nationality: string;
  dateCreated: Date;
  dateUpdated: Date;
  modifyBy: string;
  status: string;
  fullname: string;
  passportURL: string;

}
